export default function authenticated({
  next,
  axios}) {
  const role = localStorage.getItem('role');
  if ([3, 6, 12, 17, 18].indexOf(parseInt(role, 10)) > -1) {
    return next();
  } else {
    localStorage.removeItem('role');
    localStorage.removeItem('token');
    delete axios.defaults.headers.common['Authorization'];
  }
}